<template>
  <b-container class='complaints text-center mt-1 pt-4 pl-2 pr-2 mb-4'>
    <b-button
      class="complaints__back-btn d-none d-sm-block"
      variant="outline-secondary"
      size="sm"
      @click="$router.push({ name: 'welcome' })">
        <simple-line-icons class="complaints__back-icon" icon="arrow-left" size="small" no-svg/>
        <span class="complaints__back-icon">{{ $t('swal.cancel-button') }}</span>
    </b-button>
    <div class='title'>
      <p class='font-weight-bold'>{{ $t('complaints.title') }}</p>
    </div>
    <div class="main-form mt-4 ">
      <b-form class="b-form-padding" @submit.prevent='handleSubmit'>
        <b-row>
          <b-col
            lg="8"
            md='10'
            offset-lg="2"
            offset-md="1">
            <fieldset class="fieldset-border">
                <legend class="fieldset-border">
                    {{ $t('complaints.general.title') }}
                </legend>
                <b-input-group
                    size='sm'
                    class='mb-3'>
                    <b-input-group-text class='font-weight-bold' slot="prepend">{{ $t('complaints.general.complaint-for-label') }}</b-input-group-text>
                    <b-form-select
                    @change='getComplaintForText($event)'
                    ref='complaintReason'
                    size="sm"
                    v-model="selected"
                    :options="options"
                    >
                    </b-form-select>
                </b-input-group>

                <b-input-group
                    size='sm'
                    class='mb-3'>
                    <b-input-group-text class='font-weight-bold' slot="prepend">{{ $t('complaints.general.date-label') }}</b-input-group-text>
                    <b-form-input
                    @blur='getDate'
                    type="date"
                    size="sm"
                    :class="{ 'is-invalid': submitted && !date  }">
                    >
                    </b-form-input>
                    <div v-show="submitted && !date " class="invalid-feedback">{{ $t('complaints.general.date-error') }}</div>
                </b-input-group>
            </fieldset>
            <component
              :is="selected"
              :submitted="submitted"
              :legendTitle='legendTitle'
              :vehicleValue='vehicleValue'
              @passVehicleValue='getVehicleValue'
              @passRouteValue='getRouteValue'/>
            <fieldset class="fieldset-border">
              <legend class="fieldset-border">{{ $t('complaints.notes.title') }}</legend>
              <b-form-textarea
                id="textarea"
                v-model="complaintNotes"
                placeholder="Your complaints..."
                rows="5"
                :class="{ 'is-invalid': submitted && !complaintNotes  }">
              </b-form-textarea>
              <div v-show="submitted && !complaintNotes " class="invalid-feedback">{{ $t('complaints.notes.notes-error') }}</div>
            </fieldset>
            <b-button  type='submit' class='mb-4 mt-2' variant="primary" >
              {{ $t('submit-button') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import { successPrompt, confirmPrompt } from '@/prompts'
import common from '@/mixins/common'
import ComplaintsForRoute from '../components/ComplaintForRoute'
import ComplaintsForVehicle from '../components/ComplaintForVehicle'

export default {
  name: 'welcome',
  components: {
    'complaints-for-route': ComplaintsForRoute,
    'complaints-for-vehicle': ComplaintsForVehicle
  },
  mixins: [common],
  data () {
    return {
      selected: 'complaints-for-route',
      options: [
        { value: '', text: this.$i18n.t('complaints.other.title') },
        { value: 'complaints-for-route', text: this.$i18n.t('complaints.route.title') },
        { value: 'complaints-for-vehicle', text: this.$i18n.t('complaints.vehicle.title') }
      ],
      legendTitle: this.$i18n.t('complaints.route.title'),
      date: '',
      complaintNotes: '',
      vehicleValue: '',
      route: '',
      readyForValidation: true,
      submitted: false

    }
  },

  methods: {
    getComplaintForText (text) {
      const id = this.$refs.complaintReason.options.find((input) => input.value === text)
      this.legendTitle = 'text' in id && id.text
      this.resetValues()
    },
    getVehicleValue (value) {
      this.vehicleValue = value
    },
    getRouteValue (value) {
      this.route = value
    },
    getDate (date) {
      this.date = date.target.value
    },
    mapFieldsForValidation () {
      const mapValues = ['date', 'complaintNotes']
      this.readyForValidation = mapValues.every((input) => this[input])
      if ((this.legendTitle === this.$i18n.t('complaints.route.title') && (!this.route || !this.vehicleValue)) ||
        (this.legendTitle === this.$i18n.t('complaints.vehicle.title') && !this.vehicleValue)) {
        this.readyForValidation = false
      }
    },
    handleSubmit () {
      this.submitted = true
      this.mapFieldsForValidation()
      if (!this.readyForValidation) {
        return this.scrollToMissingFields()
      }
      const options = {
        title: this.$i18n.t('swal.confirm-title'),
        html:
          `<p class='text-left font-weight-bold'><u>${this.$i18n.t('complaints.general.title')}</u><p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('complaints.general.complaint-for-label')}: <span class='font-weight-bold'> ${this.legendTitle} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('complaints.general.date-label')}: <span class='font-weight-bold'> ${this.date} </span> </p>` +

          `<p class='text-left font-weight-bold'><u>${this.selected === 'complaints-for-route' || this.selected === 'complaints-for-vehicle' ? this.legendTitle : ''}</u><p>` +
          `${this.selected === 'complaints-for-route'
            ? `<p class='text-left pl-4 h6'> ${this.$i18n.t('complaints.route.title')}: <span class='font-weight-bold'> ${this.route} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('complaints.vehicle.title')}: <span class='font-weight-bold'> ${this.vehicleValue} </span> </p>`
            : this.selected === 'complaints-for-vehicle'
              ? `<p class='text-left pl-4 h6'> ${this.$i18n.t('complaints.vehicle.title')}: <span class='font-weight-bold'> ${this.vehicleValue} </span> </p>`
              : ''
          }` +

          `<p class='text-left font-weight-bold'><u>${this.$i18n.t('complaints.notes.title')}</u><p>` +
          `<p class='text-left pl-4 h6'><span class='font-weight-bold'> ${this.complaintNotes} </span> </p>`,
        customClass: {
          confirmButton: 'btn btn-primary ml-3',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
        confirmButtonText: this.$i18n.t('swal.yes-button'),
        cancelButtonText: this.$i18n.t('swal.cancel-button')
      }
      confirmPrompt(options)
        .then((result) => {
          const message = `<p class='font-weight-bold'>${this.$i18n.t('swal.complaint.successful-submit')}<p>`
          const successOptions = {
            confirmButtonText: this.$i18n.t('swal.ok-button')
          }
          successPrompt(message, successOptions)
            .then(() => {
              this.$router.push('/')
            })
          // eslint-disable-next-line
              .catch((err) => this.$router.push('/'))
        })
        .catch(() => {

        })
    },
    resetValues () {
      this.submitted = false
      this.vehicleValue = ''
      this.route = ''
    }
  }
}
</script>

<style lang='scss'>
.complaints {
  position: relative;
  &__back {
    &-btn {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    &-icon {
      vertical-align: middle;
      font-size: 14px !important;
    }
  }
  .title{
    font-size: 22px;
  }
  .b-form-padding{
    padding:0px 30px;
  }
  @media (max-width: 576px) {
    .b-form-padding {
      padding: 0;
    }
  }
}
</style>
