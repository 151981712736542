<template>
    <fieldset class="fieldset-border">
        <legend class="fieldset-border">
            {{legendTitle}}
        </legend>
        <b-input-group
            size='sm'
            class='mb-3'>
            <b-input-group-text class='font-weight-bold' slot="prepend">{{ $t('complaints.vehicle.vehicle-label') }}</b-input-group-text>
            <b-form-input
            @blur="getValue($event.target.value)"
            size="sm"
            :class="{ 'is-invalid': submitted && !vehicleValue  }"
            >
            </b-form-input>
            <div v-show="submitted && !vehicleValue " class="invalid-feedback">{{ $t('complaints.vehicle.vehicle-error') }}</div>
        </b-input-group>
    </fieldset>
</template>

<script>
export default {
  props: {
    'legend-title': { type: String },
    submitted: { type: Boolean },
    vehicleValue: { type: String }
  },
  data () {
    return {

    }
  },
  methods: {
    getValue (value) {
      this.$emit('passVehicleValue', value)
    }
  }
}
</script>
