<template>
    <fieldset class="fieldset-border">
        <legend class="fieldset-border">
            {{legendTitle}}
        </legend>
        <b-input-group
            size='sm'
            class='mb-3'>
            <b-input-group-text class='font-weight-bold' slot="prepend">{{ $t('complaints.route.route-label') }}</b-input-group-text>
            <b-form-select
            size="sm"
            v-model="selected"
            :options="options"
            @change='getRouteValue(selected)'
            :class="{ 'is-invalid': submitted && !selected  }"
            >
            <template slot="first">
              <option :value="null" disabled>{{ $t('complaints.route.routeDropdown-default') }}</option>
            </template>
            </b-form-select>
            <div v-show="submitted && !selected " class="invalid-feedback">{{ $t('complaints.route.route-error') }}</div>
        </b-input-group>
        <b-input-group
            size='sm'
            class='mb-3'>
            <b-input-group-text class='font-weight-bold' slot="prepend">{{ $t('complaints.vehicle.vehicle-label') }}</b-input-group-text>
            <b-form-input
            size="sm"
            @blur="getValue($event.target.value)"
            :class="{ 'is-invalid': submitted && !vehicleValue  }"
            >
            </b-form-input>
            <div v-show="submitted && !vehicleValue " class="invalid-feedback">{{ $t('complaints.vehicle.vehicle-error') }}</div>
        </b-input-group>
    </fieldset>
</template>

<script>
export default {
  props: {
    'legend-title': { type: String },
    submitted: { type: Boolean },
    vehicleValue: { type: String }
  },
  data () {
    return {
      selected: null,
      options: [
        { value: this.$i18n.t('complaints.route.route.schoolbound'), text: this.$i18n.t('complaints.route.route.schoolbound') },
        { value: this.$i18n.t('complaints.route.route.outbound'), text: this.$i18n.t('complaints.route.route.outbound') }
      ]
    }
  },
  methods: {
    getValue (value) {
      this.$emit('passVehicleValue', value)
    },
    getRouteValue (value) {
      this.$emit('passRouteValue', value)
    }
  }
}
</script>
